import { Icon, Image, LegacyCard, Page, Text, TextField } from "@shopify/polaris"
import axios from "axios"
import { useCallback, useEffect, useState } from "react";
import search from "../images/search.svg";
import { Accordion } from "react-bootstrap"
import { useSelector } from "react-redux";
import { SearchMinor } from "@shopify/polaris-icons";
import { tokenEndpoint } from "../config";

const ViewFaq = () => {
  const [getdata, setgetdata] = useState([])
  const [textFieldValue, setTextFieldValue] = useState('');
  const shop_id = useSelector(state => state.Storedata.sdata);
  const copy = btoa(unescape(encodeURIComponent("")))
  
  function htmlspecialchars_decode(string = '', quoteStyle = 2) {
    string = string || '';
    string = string.toString()
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
    let OPTS = {
      'ENT_NOQUOTES': 0,
      'ENT_HTML_QUOTE_SINGLE': 1,
      'ENT_HTML_QUOTE_DOUBLE': 2,
      'ENT_COMPAT': 2,
      'ENT_QUOTES': 3,
      'ENT_IGNORE': 4
    }
    if (typeof quoteStyle !== 'number') {
      quoteStyle = OPTS[quoteStyle];
    }
    if (quoteStyle && OPTS.ENT_HTML_QUOTE_SINGLE) {
      string = string.replace(/&#039;/g, "'")
    }
    if (quoteStyle !== 0) {
      string = string.replace(/&quot;/g, '"')
    }
    string = string.replace(/&amp;/g, '&')
    return string
  }
  useEffect(() => {
    if (shop_id.authentication) {
      apigetcall()
    }
  }, [shop_id.authentication, textFieldValue])

  const apigetcall = () => {
    let search_val = undefined;
    if ((textFieldValue && textFieldValue.length >= 3)) {
      search_val = textFieldValue;
    }
    axios.get(tokenEndpoint + `/all_faq_listing`,
      {
        params: {
          search_val,
        },
        headers: {
          authentication: `${shop_id.authentication}`
        }
      }).then((x) => {
        setgetdata(x.data.data)
      }).catch(
        setgetdata([])
      )
    
   
  }
  const handleTextFieldChange = useCallback(
    (value) => setTextFieldValue(value),
    [],
  );
 
  return (
    <>
      <Page title="FAQ">
        <LegacyCard>
          <LegacyCard.Section>
            <div className="text">
              <TextField
                prefix={<Icon source={SearchMinor} color="base" />}
                placeholder=" Search Faq Titles"
                value={textFieldValue}
                onChange={handleTextFieldChange}
                autoComplete="off"
              />
            </div>
          </LegacyCard.Section>
          {
            getdata.length > 0 ? <Accordion defaultActiveKey='0'>
              {
                getdata?.map((x, i) => {
                  let decodedStringAtoB = x.description != null ? decodeURIComponent(escape(window.atob(`${x.description}`))) : ""
                  return (
                    <>
                      <Accordion.Item eventKey={i} key={i}>
                        <Accordion.Header className="headeracc">{htmlspecialchars_decode(x.title)}</Accordion.Header>
                        <Accordion.Body>
                          <div dangerouslySetInnerHTML={{ __html: decodedStringAtoB }}></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  )
                })
              }
            </Accordion> : <LegacyCard> <div className="NoDataFound">
              <Image
                alt="Empty search results"
                src={search}
                draggable="false"
              />
              <Text variant="headingXl" as="h4">
                No Data Found
              </Text>
              <br />
              <p>Try changing search term</p>
            </div></LegacyCard>
          }
        </LegacyCard>
      </Page>
    </>
  )
}
export default ViewFaq
