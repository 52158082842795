import {
    Page,
    Layout,
    LegacyCard,
    Button,
    Text,
    Toast,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { apiEndpoint } from "../config";
import axios from "axios";
import { useSelector } from 'react-redux';
import { object } from 'yup';
const Trackpod = () => {
    const shop_data = useSelector((state) => state.Storedata.sdata)
    const tokanvalue = useSelector((state) => state.Storedata.sdata.authentication)
    const pod_status = useSelector((state) => state.Storedata.sdata.pod_status);
    const [status, setstatus] = useState((pod_status == 1) ? "Disable" : "Enable");
    const [statusvalue, setstatusvalue] = useState((pod_status == 0) ? 0 : 1)
    const [active, setActive] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false)
    const [toastMessage, setToastMessage] = useState('');
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    useEffect(() => {
        if(Object.keys(shop_data).length > 0){
            setstatus((pod_status == 0) ? "Enable" : "Disable");
            setstatusvalue((pod_status == 0) ? 0 : 1);
        }
    }, [shop_data])

    const podstatus = async (statusvalue) => {
        setButtonLoader(true)
        try {
            const podstatusres = await axios.put(
                apiEndpoint + `/node/admin_api/pod_status?POD_Status=${statusvalue}`,
                {},
                {
                    headers: {
                        authentication: tokanvalue,
                        "Content-Type": "application/json",
                    }
                }
            );
            if(podstatusres.status == 200){
                setstatusvalue(statusvalue);
                setstatus((statusvalue == 0) ? "Enable" : "Disable");
                setButtonLoader(false);
            }
            setToastMessage(statusvalue == 0 ? "Disabled successfully" : "Enabled successfully")
            toggleActive()
        } catch (error) {
            setButtonLoader(false);
            setToastMessage(`Error:-${error.response.data.message}`);
            toggleActive()
        }
    };
    return (
        <Page >
            <Layout Layout>
                <div className='trackpodsettings'>
                    <Layout.AnnotatedSection
                        title="Track POD sync status"
                        description="Enable or disable Track POD sync on your store. Click enable Track POD sync."
                    >
                        <div className='trackpodstatus'>
                            <LegacyCard sectioned>
                                <div className='trackpodstatusbtn'>
                                    <Text variant="bodyMd" as="p">
                                        Click the button to enable Track POD sync.
                                    </Text>
                                    <Button primary={(statusvalue == 0) ? true : false} destructive={(statusvalue == 1) ? true : false} loading={buttonLoader} onClick={() => podstatus((statusvalue == 0) ? 1 : 0)}>{status}</Button>
                                    {active && <Toast content={toastMessage} onDismiss={toggleActive} duration={1500} />}
                                </div>
                            </LegacyCard>
                        </div>
                    </Layout.AnnotatedSection>
                </div>
            </Layout>
        </Page>
    );
}
export default Trackpod
