import React from "react"
import { Route } from "react-router"
import Listfqa from '../../pages/Listfaq';
import Addfaq from '../../pages/Faqadd';
import ViewFaq from "../../pages/ViewFaq";
import { BrowserRouter as Router } from "react-router-dom";
import Trackpod from "../../pages/Trackpod";

export const Routes = () => {
    return (
        <div>
            <Router>
                <Route exact path='/' component={ViewFaq} />
                <Route exact path='/list_faq' component={Listfqa} />
                <Route exact path='/add_faq' component={Addfaq} />
                <Route exact path='/add_faq/:faq_id' component={Addfaq} />
                <Route exact path='/view_faq' component={ViewFaq} />
                <Route exact path='/trackpod' component={Trackpod} />

            </Router>
        </div >
    )
}