import { configureStore } from '@reduxjs/toolkit'
import StoreDataSlice from "./features/StoreDataSlice"

 const store = configureStore({
  reducer: {
    Storedata : StoreDataSlice
  },
})


export default store