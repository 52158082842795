import { createSlice } from '@reduxjs/toolkit'

 const StoreDataSlice = createSlice({
    name: 'StoreData',
    initialState:{
        sdata:[]
    },
    reducers: {
        addData: (state, action) => {
           state.sdata = action.payload
        },
    },
  })
  
  export const { addData } = StoreDataSlice.actions
  
  export default StoreDataSlice.reducer