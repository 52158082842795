import React, { useState, useCallback, useEffect } from "react";
import { SearchMinor, EditMinor, DeleteMinor } from "@shopify/polaris-icons";
import {
  LegacyCard,
  Icon,
  Page,
  Text,
  DataTable,
  ButtonGroup,
  Button,
  TextField,
  Image,
  Modal,
  Toast,
  Pagination,
  SkeletonPage,
  SkeletonBodyText,
  Layout,
  Divider,
  SkeletonThumbnail,
} from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import "../App.css"
import search from "../images/search.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { apiEndpoint } from "../config";
import { NavLink } from "react-router-dom";

function Listfaq() {
  const [faqdata, setfaqdata] = useState();
  const [totalfaqdata, settotalfaqdata] = useState()
  const [totalfilteredfaq, settotalfilteredfaq] = useState(1)
  const [page, setpage] = useState(1);
  const [totalpages, setTotalPages] = useState(1);
  const [searchvalue, setsearchvalue] = useState();
  const [Warnactive, setWarnActive] = useState(false);
  const [Buttonid, setButtonid] = useState();
  const [Toastactive, setToastActive] = useState(false);
  const tokanvalue = useSelector((state) => state.Storedata.sdata.authentication);
  const storeclientid = useSelector((state) => state.Storedata.sdata);
  const Limit = 10

  const toggleActive = useCallback(() => setToastActive((Toastactive) => !Toastactive), []);

  const toastMarkup = Toastactive ? (<Toast content=" Faq Deleted " onDismiss={toggleActive} duration={1500} />) : null;

  const handlewarn = useCallback(() => setWarnActive(!Warnactive), [Warnactive]);

  const deletecalled = () => {
    deletefaq(Buttonid); handlewarn();
  };

  const handleDelete = (id) => {
    handlewarn();
    setButtonid(id);
  };
  function htmlspecialchars_decode(string = '', quoteStyle = 2) {
    string = string || '';
    string = string.toString()
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
    let OPTS = {
      'ENT_NOQUOTES': 0,
      'ENT_HTML_QUOTE_SINGLE': 1,
      'ENT_HTML_QUOTE_DOUBLE': 2,
      'ENT_COMPAT': 2,
      'ENT_QUOTES': 3,
      'ENT_IGNORE': 4
    }
    if (typeof quoteStyle !== 'number') {
      quoteStyle = OPTS[quoteStyle];
    }
    if (quoteStyle && OPTS.ENT_HTML_QUOTE_SINGLE) {
      string = string.replace(/&#039;/g, "'")
    }
    if (quoteStyle !== 0) {
      string = string.replace(/&quot;/g, '"')
    }
    string = string.replace(/&amp;/g, '&')
    return string
  }
  const deletefaq = async (id) => {
    try {
      await axios.delete(
        apiEndpoint + `/node/admin_api/delete_faq`,
        {
          headers: {
            authentication: tokanvalue,
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            store_client_id: `${storeclientid.store_client_id}`,
            id: id,
          }),
        }
      )
      Getfaqlist(searchvalue, page);
      toggleActive();
    } catch (error) {
      console.log(error);
    }
  };

  const handleValue = useCallback((sValue) => setsearchvalue(sValue), []);

  useEffect(() => {
    if (tokanvalue) {
      Getfaqlist(searchvalue, page);
    }
  }, [tokanvalue, searchvalue, page]);

  const Getfaqlist = async (searchvalue, page) => {
    try {
      let search_val = undefined;
      if ((searchvalue && searchvalue.length >= 3)) {
        search_val = searchvalue;
        if (page > 1 && totalfilteredfaq == 1) {
          setpage(1)
          settotalfilteredfaq(0);
        } else {
          setpage(page)
        }
      }
      const response = await axios.get(
        apiEndpoint + `/node/admin_api/faq_listing`,
        {
          params: {
            store_client_id: storeclientid.store_client_id,
            limit: Limit,
            page: (page) ? page : 1,
            search_val,
          },
          headers: {
            authentication: tokanvalue,
            "Content-Type": "application/json",
          },
        }
      );
      const total_data = response.data.data.totalData;
      const curr_page = Math.ceil(total_data / Limit)
      setTotalPages(curr_page)

      if (response.data.data.length !== 0) {
        setfaqdata(response.data.data.list);
        settotalfaqdata(response.data.data.totalData);
      } else {
        setfaqdata([]);
      }
      if (searchvalue == undefined || searchvalue == '') {
        setpage(page)
        settotalfilteredfaq(1);
      }
    } catch (error) {
      setfaqdata([]);
      console.log(error);
    }
  };

  const rows =
    faqdata && faqdata.length
      ? faqdata?.map((item) => [
        htmlspecialchars_decode(item.title),
        <ButtonGroup >
          <Button plain id={item.id} >
            <NavLink to={`/add_faq/${item.id}`}> <Icon source={EditMinor} color="base" /></NavLink>
          </Button>
          <Button
            plain
            destructive
            id={item.id}
            onClick={() => handleDelete(item.id)}
          >
            <Icon source={DeleteMinor} color="#000" />
          </Button>
        </ButtonGroup>
      ])
      : [];

  const ary = [0, 1, 2, 3, 4, 5, 6, 7, 8]
  return (
    faqdata ?
      <Page>
        <LegacyCard title="Faq Listing">
          <div className="listbtn"><NavLink to='/add_faq'> <Button primary>Add FAQ</Button></NavLink></div>
          <LegacyCard.Section>
            <TextField
              prefix={<Icon source={SearchMinor} color="base" />}
              placeholder=" Search Faq Titles"
              value={searchvalue}
              onChange={handleValue}
              autoComplete="off"
            />
            <br />
            {!faqdata?.length ? (
              <div className="NoDataFound">
                <Image
                  alt="Empty search results"
                  src={search}
                  draggable="false"
                />
                <Text variant="headingXl" as="h4">
                  No Data Found
                </Text>
                <br />
                <p>Try changing search term</p>
              </div>
            ) : (
              <LegacyCard>
                <DataTable
                  columnContentTypes={["text"]}
                  headings={[
                    <Text variant="headingMd" as="h6">
                      Faq Title
                    </Text>,
                    <Text variant="headingMd" as="h6">
                      Actions
                    </Text>,
                  ]}
                  rows={rows}
                />
                {totalfaqdata && totalfaqdata > Limit ? (
                  <div className="pagination">
                    <Pagination
                      previousTooltip="Previous"
                      hasPrevious={(page === 1 || page > totalpages) ? false : true}
                      onPrevious={() => Getfaqlist(searchvalue, page - 1)}
                      hasNext={(totalpages === page) ? false : true}
                      nextTooltip="Next"
                      onNext={() => Getfaqlist(searchvalue, page + 1)}
                    />
                  </div>
                ) : null}
              </LegacyCard>
            )}
          </LegacyCard.Section>
          <div>
            <Modal
              open={Warnactive}
              onClose={handlewarn}
              title="Delete faq"
              primaryAction={{
                content: "Delete",
                onAction: deletecalled,
                destructive: true,
              }}
              secondaryActions={[
                {
                  content: "cancel",
                  onAction: handlewarn,
                },
              ]}
            >
              <Modal.Section>
                <Text>Are you sure, you want to delete this faq </Text>
              </Modal.Section>
            </Modal>
          </div>
          {toastMarkup}
        </LegacyCard>
      </Page>
      :
      <Page>
        <LegacyCard >
          <SkeletonPage primaryAction>
            <Layout.Section>
              <SkeletonBodyText lines={1} />
            </Layout.Section>
            <br />
            <LegacyCard >
              <SkeletonPage primaryAction />
              <Divider borderColor="border-inverse" />
              {
                ary.map(() => {
                  return (
                    <>
                      <div style={{ display: "flex" }}>
                        <SkeletonPage />
                        <div style={{ display: "flex", marginLeft: "35em" }}>
                          <div style={{ marginTop: "1em" }}>
                            <SkeletonThumbnail size="small" />
                          </div>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <div style={{ marginTop: "1em" }}>
                            <SkeletonThumbnail size="small" />
                          </div>                </div>
                      </div>
                      <Divider borderColor="border-inverse" />
                    </>
                  )
                })
              }
              <div style={{ display: "flex", marginLeft: "25em", padding: "10px" }}>
                <SkeletonThumbnail size="medium" />
                <span>&nbsp;</span>
                <SkeletonThumbnail size="medium" />
              </div>
            </LegacyCard>
          </SkeletonPage>
        </LegacyCard>
      </Page>
  );
}
export default Listfaq;
