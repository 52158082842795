let apiEndpoint, tokenEndpoint, shop_name;
let config = {};
let alexaCurrentScriptUrl = document.currentScript.src;
let alexaDomainName = alexaCurrentScriptUrl.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
shop_name = params?.shop ? params?.shop : '';
const host = params?.host;
if (alexaDomainName === "localhost" || alexaDomainName === "localhost:3001" || alexaDomainName === "localhost:3000") {
    apiEndpoint = `http://localhost:4000`;
    tokenEndpoint = 'http://localhost:4000/node/admin_api';
    shop_name = 'jaydip-preorder.myshopify.com';
}else if (alexaDomainName === "alexavoice-prod-front.floraldesignbyheidi.com") { /* for live app*/
    config = {
        apiKey: `68e6b93dda2b6c7bea57e820283132bb`,
        forceRedirect: true,
        host:host
    }
    apiEndpoint = `https://alexavoice-prod.floraldesignbyheidi.com`;
    tokenEndpoint = 'https://alexavoice-prod.floraldesignbyheidi.com/node/admin_api';
}else if (alexaDomainName === "alexavoice-dev-front.floraldesignbyheidi.com") { /* for dev app*/
    config = {
        apiKey: `3c0c769b36335e7e43231107ec7351ce`,
        forceRedirect: true,
        host:host
    }
    apiEndpoint = `https://alexavoice-dev.floraldesignbyheidi.com`;
    tokenEndpoint = 'https://alexavoice-dev.floraldesignbyheidi.com/node/admin_api';
}
export {
    apiEndpoint,
    tokenEndpoint,
    config,
    shop_name,
    host
};