import "./App.css";
import { Routes } from "../src/router/routes/index";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { shop_name, apiEndpoint,config,host } from "./config";
import "@shopify/polaris/build/esm/styles.css";
import { useEffect, useState } from "react";
import { addData } from "./redux/features/StoreDataSlice";
import { useDispatch } from "react-redux";
import {  NavigationMenu, Provider, TitleBar } from "@shopify/app-bridge-react";

function App() {

  let Shop_data
  const dispatch = useDispatch();
  const [shopData,setShopData] = useState()
  const StoreData = async () => {
    try {
      const response = await axios.post(
        apiEndpoint + `/node/admin_api/generate-token`,
        {
          shop: shop_name,
        }
      ).catch( (err) => {
        if(err.response.data.data?.url && window.location.hostname !== 'localhost'){
          window.parent.location.href = err.response.data.data?.url + `/?shop=${shop_name}&host=${host}`
        }
      });      
      Shop_data = {
        'Shop_info': response.data.data.shop_data[0],
        'authentication': response.data.data.token,
        'store_client_id': response.data.data.shop_data[0].store_client_id,
        'pod_status': response.data.data.shop_data[0].pod_status
      }
      setShopData(Shop_data);
      dispatch(addData(Shop_data));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    StoreData();
  }, []);
  if(shopData){
    if (window.location.hostname === 'localhost') {
      return (
        <>
          <div>
            <Routes />
          </div>
        </>
      );
    } else {
      const secondaryActions = [{ content: 'Edit Question', url: '/list_faq' }, { content: 'View FAQ', url: '/view_faq' }];
      return (
        <>
          <Provider config={config}>
            <TitleBar
              title="Alexa Voice Order Management"
              secondaryActions={secondaryActions}
            />
            <NavigationMenu
              navigationLinks={[
                {
                  label: 'Edit Question',
                  destination: '/list_faq',
                },
                {
                  label: 'View FAQ',
                  destination: '/view_faq',
                },
                {
                  label: 'Track POD',
                  destination: '/trackpod',
                },
              ]}
              matcher={(link, location) => link.destination === location.pathname}
            />
          </Provider>
          <Routes />
        </>
      );
    }
  }else{
    return(<></>);
  }
}

export default App;
