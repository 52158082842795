import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./redux/Store";
import { Provider } from "react-redux";
import { AppProvider, Frame } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

ReactDOM.render(
    <AppProvider i18n={enTranslations}>
      <Frame>
        <Provider store={store}>
            <App />
        </Provider>
      </Frame>
    </AppProvider>
    ,
  document.getElementById("root")
);
