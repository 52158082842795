import { Button, Icon, LegacyCard, Page, TextField, Toast } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { useFormik } from 'formik';
import { tokenEndpoint } from "../config";
import * as yup from "yup"
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { ArrowLeftMinor } from '@shopify/polaris-icons';

const Add_FQAs = () => {
  let { faq_id } = useParams();
  const [DataObject, setDataObject] = useState({ title: '', description: '' })
  const [array, setArray] = useState([])
  const [active, setActive] = useState(false);
  let [buttonLoader, setButtonLoader] = useState(false)
  const shop_id = useSelector(state => state.Storedata.sdata);
  const [initialState, setInitialState] = useState({ title: '', description: '' })
  const [toastMessage, setToastMessage] = useState('');
  const [value1, setValue1] = useState('');

  let history = useHistory();

   
  function htmlspecialchars(string = '', quoteStyle = 2, charset = '', doubleEncode = true) {
    string = string || '';
    string = string.toString();
    if (doubleEncode) {
      string = string.replace(/&/g, '&amp;');
    }
    string = string.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    let OPTS = {
      'ENT_NOQUOTES': 0,
      'ENT_HTML_QUOTE_SINGLE': 1,
      'ENT_HTML_QUOTE_DOUBLE': 2,
      'ENT_COMPAT': 2,
      'ENT_QUOTES': 3,
      'ENT_IGNORE': 4
    };
    if (typeof quoteStyle !== 'number') {
      quoteStyle = OPTS[quoteStyle];
    }
    if (quoteStyle && OPTS.ENT_HTML_QUOTE_SINGLE) {
      string = string.replace(/'/g, '&#039;');
    }
    if (quoteStyle !== 0) {
      string = string.replace(/"/g, '&quot;');
    }
    if (charset == 'UTF-8') {
      return new Buffer.from(string, 'utf-8').toString();
    } else {
      return string;
    }
  }

  const handleChange = useCallback(
    (newValue) => setValue1(newValue),
    [],
  );
  const formSchema = yup.object().shape({
    title: yup.string().required('Please Enter Your title'),
  })
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      createFqa(values);
    },
  });

  useEffect(() => {
    if (faq_id) {
      if (shop_id.authentication) {
        axios.get(tokenEndpoint + `/single_faq?id=${faq_id}`, {
          headers: {
            authentication: `${shop_id.authentication}`
          }
        }).then((x) => {
          formik.setFieldValue('title', x.data.data[0].title)
          let decodedStringAtoB = decodeURIComponent(escape(atob(`${x.data.data[0].description}`)))
          formik.setFieldValue('description', decodedStringAtoB)
        })
      }

    }
  }, [faq_id, shop_id])

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastMessage} onDismiss={toggleActive} duration={1000} />
  ) : null;

  const createFqa = async () => {
    setButtonLoader(true)
    setDataObject({ ...DataObject })
    array.push(DataObject)
    setArray([...array])
    if (!faq_id) {
      axios.post(tokenEndpoint + `/create_faq`,
        {
          title: htmlspecialchars(formik.values.title),
          description: DataObject.description,
          store_client_id: `${shop_id.Shop_info?.store_client_id}`
        },
        {
          headers: {
            authentication: `${shop_id.authentication}`
          }
        },
      ).then((res) => {
        if (res.status == 200) {
          setButtonLoader(false);
          setToastMessage(res.data.message);
          toggleActive();
          history.push('/list_faq');
        }
      })
    }
    else {
      axios.put(tokenEndpoint + `/update_faq`,
        {
          store_client_id: `${shop_id.Shop_info?.store_client_id}`,
          id: faq_id,
          data: {
            title: formik.values.title,
            description: DataObject.description,
          }
        },
        {
          headers: {
            authentication: `${shop_id.authentication}`
          }
        }).then((x) => {
          if (x.status == 200) {
            setButtonLoader(false);
            setToastMessage(x.data.message);
            toggleActive();
            history.push('/list_faq');
          }
        })
    }
  }
  const encodeData = (editor) => {
    const copy = window.btoa(unescape(encodeURIComponent(editor)))
    DataObject.description = copy
  }
  return (
    <>
      <Page>
        <div className='back_btn'>
          <Button>
            <NavLink to='/list_faq'><Icon source={ArrowLeftMinor} color="base" /></NavLink>
          </Button>
          <div className='faq_title'>{faq_id ? 'UPDATE FAQ' : 'ADD FAQ'}</div>
        </div>
        <LegacyCard title='' sectioned>
          <TextField
            label='Title'
            name='title'
            value={formik.values.title}
            onChange={(val) => { formik.setFieldValue('title', val) }}
            error={formik.errors.title && formik.touched.title ? formik.errors?.title : ""} />
          <div className='ck-style'>
            <p>Description</p>
            <CKEditor
              editor={ClassicEditor}
              name='description'
              onReady={editor => {
                editor.setData(formik.values.description);
              }}
              data={formik.values.description}
              onChange={(event, editor) => { encodeData(editor.getData()); formik.setFieldValue('description', editor.getData()) }}
            />
          </div>
        </LegacyCard>
        <div style={{ marginTop: "20px", textAlign: "end" }}>
          <Button primary loading={buttonLoader} onClick={() => formik.handleSubmit()}>
            Save
          </Button>
          {toastMarkup}
        </div>
      </Page >
    </>
  )
}

export default Add_FQAs;